export default {
  methods: {
    getGridColumns () {
      let vm = this
      this.gridColumns = [
          {
            id: 0,
            data: 'taskNumber',
            title: 'NR CRLS',
            render (data, type, row, meta) {
              return data !== null ? data : row.id
            }
          },
          { id: 1, data: 'assignee', title: 'OPIEKUN', sortable: false },
          { id: 2, data: 'state', title: 'STATUS' },
          {
            id: 3,
            data: 'createdAt',
            title: 'UTWORZONO',
            render (data) {
              return vm.formatDate(data)
            }
          },
        { id: 4, data: 'address', title: 'ADRES' },
        { id: 5, data: 'voivodeship', title: 'WOJEWÓDZTWO' },
        { id: 6, data: 'customerSurname', title: 'NAZWISKO KLIENTA' },
        { id: 7, data: 'closedAt', title: 'PIERWSZE PRZEKAZANIE' },
        { id: 8, data: 'lastClosedAt', title: 'OSTATNIE PRZEKAZANIE' },
        { id: 9, data: 'priority', title: 'PRIORYTET' },
        { id: 10, data: 'market', title: 'RYNEK' },
        { id: 11, data: 'typeOfStudy', title: 'RODZAJ OPRACOWANIA' },
        ]
    }
  }
}
