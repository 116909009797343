<template>
  <div v-if="showGrid">
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="box box-default box-solid">
            <div class="box-body table-responsive listing-grid-container">
              <grid
                :id="gridName"
                :source="gridDataSource"
                :name="gridName"
                :columns="gridColumns"
                :actions="gridActions"
                :default-order="[4, 'desc']"
                :data-source-method="gridDataSourceMethod"
                :additional-params="additionalParams"
                :service="service"
                :custom-button="false"
                @show="actionShow"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Modals-->
  </div>
</template>

<script>
import Grid from './grid/Grid.vue'
import Page from '../../../share/content/Page.js'
import api from '../../../../api'
import moment from 'moment/moment'
import ListingSchemaMixin from './ListingSchemaMixin.js'
import DynamicBreadcrumb from '../../share/mixins/DynamicBreadcrumb.js'
import clientObjectMixin from '../share/mixins/clientObjectMixin.js'
import customSecondMenuMixin from '../share/mixins/customSecondMenuMixin.js'

export default {
  name: 'TaskListingComponent',
  components: {
    Grid
  },
  mixins: [
    DynamicBreadcrumb,
    Page,
    ListingSchemaMixin,
    clientObjectMixin,
    customSecondMenuMixin
  ],
  props: {
    filter: {
      type: Object, default () {
        return null
      }
    }
  },
  data () {
    return {
      showGrid: false,
      service: this.$route.params.service,
      events: {},
      financeImportVisible: false,
      headerTitle: {title: 'Zlecenia', description: 'Lista'},
      gridName: 'grid_task_all_intbwn',
      gridDataSource: {},
      gridColumns: [],
      gridActions: [],
      gridDataSourceMethod: 'get'
    }
  },
  computed: {
    secondMenu () {
      return [
        {
          event: {name: `${this.gridName}:main:addTask`},
          icon: 'plus',
          label: 'Dodaj zlecenie',
          acl: {service: this.service, action: `post_task__create-${this.categoryName}__${this.$route.params.client}`}
        }
      ]
    },
    categoryGroup () {
      return this.$getCategoryGroupNew(this.clientObject, this.$route.params.taskCategoryId)
    },
    categoryName () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).name
    },
    categoryLabel () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).label
    },
    categoryId () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).id
    },
    workflowId () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].id
    },
    workflowName () {
      return this.$getCategoryNew(this.clientObject, this.$route.params.taskCategoryId).workflows[0].name
    },
    workflowNameSnakeCase () {
      return this.workflowName.includes('_') ? this.workflowName.split('_').join('-') : this.workflowName
    },
    additionalParams () {
      if (this.$isWithClients(this.service)) {
        let data = {
          name: this.gridName,
          client: this.client,
          clientUuid: this.clientObject.uuid,
          type: this.categoryGroup
        }
        data['categoryId'] = this.$route.params.taskCategoryId
        data['task_category'] = this.categoryName
        return data
      }
      return false
    }
  },
  created () {
    this.getGridColumns()
  },
  mounted () {
    this.getEvents()
    this.getGridActions()
    this.getGridDataSource(this.service)
    this.gridDataSourceMethod = this.getGridDataSourceMethod()
    this.showGrid = true
    this.$events.on(this.events.addTask, this.addTask)
    this.$events.on(this.events.financeImport, this.showFinanceImportModal)
    this.addDynamicBreadcrumbLabel(`${this.service}_task_listing`, `${this.$getClient(this.service, this.client).label} - ${this.categoryLabel}`)
    this.$events.on(`${this.gridName}:dataTableLoaded`, this.handleDataTableLoaded)
    this.$events.on(this.events.addTask, this.addTask)
  },
  beforeDestroy () {
    this.$events.off(`${this.gridName}:dataTableLoaded`, this.handleDataTableLoaded)
  },
  methods: {
    handleDataTableLoaded () {
      this.$setCustomSecondMenuButtons()
    },
    getGridDataSourceMethod () {
      return this.$isWithClients(this.service) ? 'post' : 'get'
    },
    getGridDataSource (service) {
      this.gridDataSource = {service: service, url: `/tasks/datatables`}
    },
    getGridActions () {
      this.gridActions = [
        {
          id: 0,
          event: 'show',
          title: 'Szczegóły',
          icon: 'eye',
          params: ['parentId', 'supportedClass', 'appraisalWorkflow', 'categoryId'],
          acl: {
            service: this.service,
            action: [`get_task__${this.categoryName}__${this.$route.params.client}`]
          },
          getCategoryGroupFunc: (categoryId) =>  this.$getCategoryGroupNew(this.clientObject, categoryId),
          routeToItems: {
            appraisal: {
              name: `${this.service}_appraisal_task_details`,
              params: {id: 'parentId', appraisalTaskId: 'id', appraisalTaskCategory: 'categoryId'}
            },
            main: {name: `${this.service}_task_details`, params: {id: 'id'}}
          },
          router: () => this.$router
        }]
    },
    getEvents () {
      this.events = {
        financeImport: `${this.service}:finance-import:spreadsheet`,
        addTask:  `${this.gridName}:main:addTask`
      }
    },
    addTask () {
      let data = {
        type: this.categoryName,
        clientUuid: this.clientObject.uuid,
        client: this.client,
        categoryId: this.categoryId
      }
      data[this.categoryName] = true

      api.request(this.service, 'post', `/tasks`, data)
        .then((response) => {
          let location = response.headers.location.split('/')
          this.toggleLoading()
          this.actionShow(location[location.length - 1])
        })
        .catch(() => {
          this.toggleLoading()
          this.$notify({
            type: 'error',
            title: 'Wystąpił błąd',
            text: 'Nie można dodać zlecenia'
          })
        })
    },
    showFinanceImportModal () {
      this.financeImportVisible = true
    },
    actionShow (id, params) {
      if (params && params.parentId) {
        return this.$router.push({
          name: `${this.service}_appraisal_task_details`,
          params: {id: params.parentId, appraisalTaskId: id, appraisalTaskCategory: params.categoryId}
        })
      }
      this.$router.push({name: `${this.service}_task_details`, params: {id}})
    },
    formatDate (data) {
      return moment(data).locale('pl').format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>
<style>
.listing-grid-container {
  transform:rotateX(180deg)
}
.listing-grid-container > * {
  transform:rotateX(180deg)
}
</style>
